import { Injectable } from '@angular/core';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, Selector, SelectorOptions, State, StateContext } from '@ngxs/store';
import { Capacity, findParameterValue } from 'flex-app-shared';
import { tap } from 'rxjs/operators';
import { v4 } from 'uuid';
import { NgXsFormModel } from '../../../../flex-app-shared/src/lib/core/flex-ng-xs-form-sync/flex-ng-xs-form-sync';
import { APFASAuction, APFASAuctionBid, APFASAuctionBids, APFASBidStatus, APFASResource } from '../../app/shared/tso-auctions/tso-auctions';
import { TsoAuctionsService } from '../../app/shared/tso-auctions/tso-auctions.service';
import {
  TsoAuctionsActivate,
  TsoAuctionsBidsUpdatedEvent,
  TsoAuctionsDeactivate,
  TsoAuctionsSaveBidsCommand,
  TsoAuctionsUpdateAuctionsCommand,
  TsoAuctionsUpdateBidsCommand,
  TsoAuctionsUpdateBidsListCommand,
  TsoAuctionsUpdateCurrentAuctionCommand,
  TsoAuctionsUpdatedEvent
} from './tso-auctions.actions';

export class TSOAuctionsStateModel {
  active = false;
  shouldReset = false;
  shouldInitialize = true;

  auctions: APFASAuction[] = [];
  auctionsBusy = false;

  // Bids are 1:1 with an APFASAuction
  bidsForm = NgXsFormModel.defaults<Partial<APFASAuctionBids>>(null);
  bids: APFASAuctionBid[] = [];
  bidsBusy = false;
  bidsBusySaving = false;

  // Current ids used to fetch the bidsForm data
  currentBidsAuctionId: string;
  currentBidsResourceId: string;

  // The APFAS auction that is linked to the current bid
  currentAuction: APFASAuction;
  currentAuctionId: string;

  // The APFAS resource that is linked to the current bid
  currentResource: APFASResource;
  currentResourceId: string;

  indivisibleBidsAllowed: boolean;

  // Results from updating/saving
  errorResult: any;
  successResult: any;
}

@State({
  name: 'tsoAuctions',
  defaults: new TSOAuctionsStateModel()
})
@Injectable({
  providedIn: 'root'
})
@SelectorOptions({
  suppressErrors: false
})
export class TSOAuctionsState {
  constructor(private service: TsoAuctionsService) {}

  @Selector([TSOAuctionsState])
  static auctionsBusy(state: TSOAuctionsStateModel): boolean {
    return state.auctionsBusy;
  }

  @Selector([TSOAuctionsState])
  static auctions(state: TSOAuctionsStateModel): APFASAuction[] {
    return state.auctions;
  }

  @Selector([TSOAuctionsState])
  static bidsBusy(state: TSOAuctionsStateModel): boolean {
    return state.bidsBusy;
  }

  @Selector([TSOAuctionsState])
  static bidsBusySaving(state: TSOAuctionsStateModel): boolean {
    return state.bidsBusySaving;
  }

  @Selector([TSOAuctionsState])
  static successResult(state: TSOAuctionsStateModel): any {
    return state.successResult;
  }

  @Selector([TSOAuctionsState])
  static errorResult(state: TSOAuctionsStateModel): any {
    return state.errorResult;
  }

  @Selector([TSOAuctionsState.bidsForm])
  static isBidsNew(bidsFormModel: APFASAuctionBids): boolean {
    return bidsFormModel.bidId === 'new';
  }

  @Selector([TSOAuctionsState])
  static isDivisible(state: TSOAuctionsStateModel): boolean {
    return state.currentAuction.algorithmType === 'MOL';
  }

  @Selector([TSOAuctionsState])
  static isIndivisibleBidsAllowed(state: TSOAuctionsStateModel): boolean {
    return state.currentAuction?.indivisibleBidsAllowed;
  }

  @Selector([TSOAuctionsState])
  static areBidsUpdatableForCurrentAuction(state: TSOAuctionsStateModel): boolean {
    return state.currentAuction?.openForBidding;
  }

  @Selector([TSOAuctionsState])
  static currentAuction(state: TSOAuctionsStateModel): APFASAuction {
    return state.currentAuction;
  }

  @Selector([TSOAuctionsState])
  static currentResource(state: TSOAuctionsStateModel): APFASResource {
    return state.currentResource;
  }

  @Selector([TSOAuctionsState.currentAuction])
  static resources(auction: APFASAuction): APFASResource[] {
    return auction.resources;
  }

  @Selector([TSOAuctionsState.bids, TSOAuctionsState.currentAuction])
  static availableCapacities(bids: APFASAuctionBid[], currentAuction: APFASAuction): Capacity[] {
    if (!currentAuction) {
      return [];
    }
    return currentAuction.allowedBidQuantities.map((allowedQuantity) => Capacity.MW(allowedQuantity));
  }

  @Selector([TSOAuctionsState])
  static bids(model: TSOAuctionsStateModel): APFASAuctionBid[] {
    return model.bids;
  }

  @Selector([TSOAuctionsState])
  static bidsForm(model: TSOAuctionsStateModel): Partial<APFASAuctionBids> {
    return model.bidsForm.model;
  }

  @Selector([TSOAuctionsState])
  static bidStatus(model: TSOAuctionsStateModel): APFASBidStatus {
    return model.bidsForm?.model?.status;
  }

  @Action(TsoAuctionsActivate)
  activate({ patchState }: StateContext<TSOAuctionsStateModel>): void {
    patchState({
      active: true
    });
  }

  @Action(TsoAuctionsDeactivate)
  deactivate({ patchState }: StateContext<TSOAuctionsStateModel>): void {
    patchState({
      active: false, // We cannot clear the state here, since this can trigger a form update, which can cause a route change
      shouldReset: true
    });
  }

  @Action(RouterNavigation)
  routerInit({ getState, setState, patchState, dispatch }: StateContext<TSOAuctionsStateModel>, { routerState }: RouterNavigation): any {
    if (!getState().active) {
      if (getState().shouldReset) {
        setState(new TSOAuctionsStateModel());
      }
      return;
    } else if (getState().shouldInitialize) {
      dispatch(new TsoAuctionsUpdateAuctionsCommand());

      patchState({
        shouldInitialize: false
      });
    }

    const auctionId = findParameterValue(routerState, 'auctionId');
    const resourceId = findParameterValue(routerState, 'resourceId');
    patchState({
      currentAuctionId: auctionId,
      currentResourceId: resourceId
    });

    dispatch(new TsoAuctionsUpdateCurrentAuctionCommand());
  }

  @Action(TsoAuctionsUpdateAuctionsCommand)
  handleTsoAuctionsUpdateAuctionsCommand({ patchState, dispatch }: StateContext<TSOAuctionsStateModel>): any {
    patchState({
      auctionsBusy: true
    });

    return this.service.getAuctions().pipe(
      tap({
        next: (data) => dispatch(new TsoAuctionsUpdatedEvent(data)),
        error: () =>
          patchState({
            auctions: [],
            auctionsBusy: false
          })
      })
    );
  }

  @Action(TsoAuctionsUpdatedEvent)
  handleTsoAuctionsUpdatedEvent(
    { patchState, dispatch, getState }: StateContext<TSOAuctionsStateModel>,
    { data }: TsoAuctionsUpdatedEvent
  ): any {
    if (!getState().active) {
      return;
    }

    patchState({
      auctionsBusy: false,
      auctions: data
    });

    dispatch(new TsoAuctionsUpdateCurrentAuctionCommand());
  }

  @Action(TsoAuctionsUpdateBidsCommand)
  handleTsoAuctionsSelectAuctionCommand(
    { patchState, dispatch }: StateContext<TSOAuctionsStateModel>,
    { auctionId, resourceId }: TsoAuctionsUpdateBidsCommand
  ): any {
    if (!auctionId || !resourceId) {
      patchState({
        bidsForm: NgXsFormModel.defaults(null)
      });
      return;
    }

    patchState({
      bidsBusy: true
    });

    return this.service.getBids(auctionId, resourceId).pipe(
      tap({
        next: (result) => dispatch(new TsoAuctionsBidsUpdatedEvent(result)),
        error: () =>
          patchState({
            bidsForm: null,
            bidsBusy: false
          })
      })
    );
  }

  @Action(TsoAuctionsBidsUpdatedEvent)
  handleTsoAuctionsBidsUpdatedEvent(
    { getState, patchState, dispatch }: StateContext<TSOAuctionsStateModel>,
    { bids }: TsoAuctionsBidsUpdatedEvent
  ): any {
    if (!getState().active) {
      return;
    }

    const { currentAuctionId, currentResourceId, currentAuction, currentResource } = getState();

    if (!currentAuction || !currentResource) {
      return;
    }

    patchState({
      bidsBusy: false
    });

    if (!bids) {
      // Not found, initialize empty
      patchState({
        bidsForm: NgXsFormModel.defaults({
          timeSeriesId: currentAuctionId,
          bidId: 'new',
          bidReference: `PH ${currentAuction.description} ${currentResource.shortName}`
        }),
        bids: []
      });
    } else {
      patchState({
        bidsForm: NgXsFormModel.defaults(bids),
        bids: bids.bids
      });
    }
  }

  @Action(TsoAuctionsUpdateCurrentAuctionCommand)
  updateCurrentAuctionAndResource({ getState, patchState }: StateContext<TSOAuctionsStateModel>): void {
    const { auctions, currentResourceId, currentAuctionId } = getState();

    let foundAuction: APFASAuction;
    let foundResource: APFASResource;

    if (auctions && currentAuctionId) {
      foundAuction = auctions.find((auction) => auction.id === currentAuctionId);
      patchState({
        currentAuction: foundAuction
      });
    } else {
      patchState({
        currentAuction: null
      });
    }

    if (auctions && currentResourceId) {
      foundResource = foundAuction && foundAuction.resources.find((resource) => resource.resourceId?.resourceId === currentResourceId);
      patchState({
        currentResource: foundResource
      });
    } else {
      patchState({
        currentResource: null
      });
    }
  }

  @Action(TsoAuctionsUpdateCurrentAuctionCommand)
  updateBids({ getState, dispatch }: StateContext<TSOAuctionsStateModel>): any {
    const { currentResourceId, currentAuctionId, currentBidsAuctionId, currentBidsResourceId } = getState();

    if (currentBidsResourceId !== currentResourceId || currentBidsAuctionId !== currentAuctionId) {
      // Out of sync
      dispatch(new TsoAuctionsUpdateBidsCommand(currentAuctionId, currentResourceId));
    }
  }

  @Action(TsoAuctionsSaveBidsCommand)
  handleTsoAuctionsSaveBidsCommand({ patchState, getState, dispatch }: StateContext<TSOAuctionsStateModel>): any {
    const bidsForm = getState().bidsForm.model;
    const bids = getState().bids;
    const currentResource = getState().currentResource;

    const newBidsObject: APFASAuctionBids = {
      ...bidsForm,
      bids,
      // @ts-ignore
      bidId: bidsForm.bidId === 'new' ? v4().replaceAll('-', '') : bidsForm.bidId,
      resourceId: currentResource.resourceId
    } as APFASAuctionBids;

    patchState({
      bidsBusySaving: true,
      successResult: null,
      errorResult: null
    });

    return this.service.saveBids(newBidsObject).pipe(
      tap({
        next: () =>
          patchState({
            bidsBusySaving: false,
            successResult: true
          }),
        error: (error) =>
          patchState({
            bidsBusySaving: false,
            errorResult: error
          })
      }),
      tap((result) => dispatch(new TsoAuctionsBidsUpdatedEvent(result)))
    );
  }

  @Action(TsoAuctionsUpdateBidsListCommand)
  handleTsoAuctionsUpdateBidsListCommand(
    { patchState }: StateContext<TSOAuctionsStateModel>,
    { bids }: TsoAuctionsUpdateBidsListCommand
  ): void {
    patchState({
      bids
    });
  }
}
